import { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Link } from 'react-router-dom';
import Nav from './nav'
import Footer from './footer';

export default function Home() {
    const [cakes, setCakes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndexes, setCurrentIndexes] = useState({});

  const [screenHeight, setScreenHeight] = useState(window.screen.height);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.screen.height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchCakes = async () => {
      const document = collection(firestore, 'Cakes');
      const q = query(document, orderBy('postedTime'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        let temp = [];
        snapshot.docs.forEach((doc) => {
          temp.push({ ...doc.data(), id: doc.id });
        });
        setCakes(temp);
        setIsLoading(false);
      }, (error) => {
        console.error('Error fetching cakes:', error);
        setIsLoading(false);
      });

      // Clean up the listener when the component unmounts
      return unsubscribe;
    };

    fetchCakes();
  }, []);
  useEffect(() => {
    // Initialize currentIndexes state with the first index for each cake
    const initialIndexes = cakes.reduce((acc, cake) => {
      acc[cake.id] = 0;
      return acc;
    }, {});
    setCurrentIndexes(initialIndexes);
  }, [cakes]);

  const handlePrevious = (cakePhotos, id) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [id]: (prevIndexes[id] === 0 ? cakePhotos.length - 1 : prevIndexes[id] - 1),
    }));
  };
  
  const handleNext = (cakePhotos, id) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [id]: (prevIndexes[id] === cakePhotos.length - 1 ? 0 : prevIndexes[id] + 1),
    }));
  };

  return (
    isLoading === true ?
    <div>
    <div className="flex flex-col items-center justify-center w-full  " style={{minHeight:'100vh'}}>
    <div
      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
      role="status"></div>

    </div>
  
    
  
  </div>
  :
    <div>
      
        <div id='desktop'>
            <Nav component={'Home'}/>
   

            <main class="relative  flex justify-around  mb-20 " style={{height:'90vh'}}>
       
        <div style={{width:'40%'}}class=" pl-12  ">
            <div class=" mb-10">
      <h1
                class="text-4xl mt-24 font-extrabold tracking-tight text-gray-900  sm:text-5xl md:text-6xl">
                <span class=" "><span class="mb-1 ">Local </span>
               
                businesses
                 can get world-class
                </span>
                <div class="mt-2"> 
                    <span class="relative mt-3 whitespace-nowrap "><svg aria-hidden="true" viewBox="0 0 418 42"
                        class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                        preserveAspectRatio="none">
                        <path
                            d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
                        </path>
                    </svg>
                    <span class="relative"> <span class="mr-2  text-blue-600 ">
                    websites
                </span> from us</span>
                    </span>
                </div>
                
            </h1>
        
            </div>
        <Link to='/contact'>
        <a class="bg-gray-900 rounded-xl text-white font-medium px-4 py-3 w-full mt-20 hover:bg-black/80 barlow" >I want a website →
        </a></Link>
      


        </div>
        <img style={{width:'60%'}} className='-mt-20 -z-10' src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Website%20Creator-bro.svg?alt=media&token=421ad86e-6908-4b38-80eb-86af37993ecb" alt="" />
    
    </main>
    {/* <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
        backgroundColor: 'white', // Set the background color to white
      }}
    >
      <iframe
        src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Blue%20White%20UI%20Digitalism%20Music%20YouTube%20Intro.mp4?alt=media&token=0ef81930-1f74-4718-9926-073c9622fe19"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </div> */}
    <div
  className="swiper-wrapper "
 
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px',marginTop:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%', }}
  >
 <div style={{backgroundColor: "#0021db",marginTop:'200px'}} className='slide-case-studies-card w-full py-20'>
 <div className="glue-page">
    <div className="ion-stacked-list__header glue-spacer-6-bottom">
      <h2 className="ion-stacked-list__headline glue-headline glue-headline--headline-3">
        <span className="glue-font-weight-medium">
          <span style={{ fontSize: 48,color:'white' }} className='text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl'>Let us build you a modern, fully coded website </span>
        </span>
      </h2>
      <div className="glue-body--large ion-stacked-list__headline-text glue-spacer-3-top ">
        <p className='text-gray-100'>
        Our goal is to empower you with the knowledge and confidence to take control of your website, so you can focus on growing your business while we handle the technical aspects behind the scenes
        </p>
      </div>
      <div className="ion-stacked-list__container-cta glue-spacer-3-top">
    <Link to='/contact'>
    <a
        style={{backgroundColor:'white',color:'black'}}
          className="ion-button glue-button glue-button--high-emphasis"
        >
          Contact Us
        </a></Link>
      </div>
    </div>
    <div className="glue-grid">
      <div className="glue-grid__col glue-grid__col--span-1 glue-grid__col--span-0-lg" />
      <div className="glue-grid__col glue-grid__col--span-10 glue-grid__col--span-12-lg">
        <ul className="ion-stacked-list__list-grid">
          <li>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>1</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize: 20,color:'white' }}> Think</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'15px',lineHeight:'16px'}}>
              Choose a website type that suits your needs
              </p>
            </div>
          </li>
          <li>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>2</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize: 20,color:'white' }}>Let Us Know</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'15px',lineHeight:'16px'}}>
              Describe your idea to us and we will get to work building your website
              </p>
            </div>
          </li><li>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>3</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize: 20,color:'white' }}>Get your website</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'15px',lineHeight:'16px'}}>
              Get your website up and running by finalizing the payment
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
   
  </div>
 
  
</div>
<div style={{height:'50px'}}>
  
</div>
<div class="px-4 mx-auto text-center lg:px-36" style={{marginBottom:'200px',marginTop:'200px'}}>
            <span class="font-semibold text-gray-400 uppercase">Our Technology Stack</span>
          
       
            <div class="flex  justify-evenly items-center mt-8 text-gray-500  w-full">
            <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-20"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/react_original_wordmark_logo_icon_146375.png?alt=media&token=61aef2b6-fbc4-44f5-b181-ebc0f3d63d96" alt="" />                      
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-10"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/1706452773google-bard-logo-png.png?alt=media&token=5db19b25-00f5-44c2-ac4d-f5a48c47c8cd" alt="" />                      
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-12"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Primary_Horizontal_Lockup_Full_Color.png?alt=media&token=1809bf09-4354-468b-9e8f-7ea1217faa2f" alt="" />                      
                </div>
                <div class="mr-5 mb-5 lg:mb-0 ">
                <img class="h-12" src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/1_2STZmGfuaBZEACgUTTJzeg.webp?alt=media&token=33c71741-efa7-4393-a40f-b7bdd96b5c78" alt="Powered by AWS Cloud Computing"/>
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-20"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/tailwindcss_logo_icon_170649.png?alt=media&token=85e2a1eb-7f61-4680-b332-bb7232125bb0" alt="" />                      
                </div>      
            </div>
        </div>
        <div className="flex image-content-block_flexRowReverse__9cgdW" style={{marginBottom:'200px'}}>
    <div
    className="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
    style={{ backgroundColor: "#0021db",width:'50%',height:''  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU px-5 ">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        style={{ color: "#ffffff" }}
      >
        <span className="text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
        Easily track your data with your new multi-user fully coded website
        </span>
      </h2>
<Link to='/contact'>

<a class="mt-5 barlow inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white border-white border rounded-2xl sm:w-auto sm:mb-0">
                    Book a call
                </a></Link>
    </div>
  </div>

  <div
    className=""
    style={{ backgroundColor: "#0021db",width:'50%',maxHeight:'100%' }}
  >
    <img
      alt="Our Dashboard"
      className="block"
      src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(9).png?alt=media&token=9a22b0ee-5dfe-4d66-b9fa-1b92408c1daf"
    />
  </div>

</div>
    
   
  


           

<div className="flex image-content-block_flexRowReverse__9cgdW" style={{marginBottom:'200px'}}>
<img class="rounded-[2rem] overflow-hidden  bg-white " style={{width:'40%',objectFit:'cover'}} src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(10).png?alt=media&token=89dfb95d-d091-44ba-8080-a797beda9b3b"  alt=""/>
    
    <div
    className="py5 flex items-center  md-size6of12 textBlockGutter"
    style={{width:'50%',height:''  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        style={{ color: "black" }}
      >
        <span className="text-4xl font-extrabold tracking-tight leading-none  md:text-5xl lg:text-6xl">
        Your website data is instantly accessible on your phone  
        </span>
        
<p class="my-4 text-lg text-gray-500">Start developing with an open-source library of over 450+ UI components, sections, and pages built with the utility classes from Tailwind CSS and designed in Figma.</p>
<Link to='/services'>
<a class="inline-flex items-center text-lg text-blue-600 dark:text-blue-500 hover:underline">
Read more
<svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg>
</a>
</Link>
      </h2>

    </div>
  </div>


 


</div>
<div
    className="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
    style={{ backgroundColor: "#0021db",width:'100%',height:'90vh',marginBottom:'200px'  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        style={{ color: "#ffffff" }}
      >
        <span className="text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
        We build stunning websites for businesses
        </span>
      </h2>

     <Link to='/contact'>
     <a class="mt-5 barlow inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white border-white border rounded-2xl sm:w-auto sm:mb-0">
                    Book a call
                </a></Link>
    </div>
  </div>



<div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="title-holder-align-bottom">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        Featured&nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        projects
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
    <div
      data-w-id="79057d9c-d2cf-dc34-a67a-c1fe0bc1a30a"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      <div
        className="btn-more-text btn-more-text-home-image"
        style={{
          fontSize:'11px',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        recent projects
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>
    </div>

   
  </div>
</div>

<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)" }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <a href="https://shewitpastry.shop" target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Shewit Pastry</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            We built a fully functional cake pastry website with an integrated ordering system 
            </p>
          </div>
        </div>
        <div
          className="holder"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(12)124-Photoroom.png?alt=media&token=77f71842-b26f-45b7-aea6-21851f7c4aa2"
            loading="eager"
            alt=""
            width="500px"
            style={{minWidth:'450px',objectFit:'contain'}}
          />
         
        </div>
      </div>
    </a>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <a href="https://keshtacademy.com" target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Kesht Academy</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            By combining our technical expertise, creative vision, and collaborative approach, we've successfully delivered a tailored eLearning solution that empowers our clients to engage their audiences, enhance learning outcomes, and stay ahead of the curve in the dynamic world of online education.
            </p>
          </div>
        </div>
        <div
          className="holder"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(15)8.png?alt=media&token=6e6efa54-615c-4855-8c34-557966b3e61f"
            loading="eager"
            alt=""
            style={{minWidth:'450px',objectFit:'contain'}}
          />
         
        </div>
      </div>
    </a>
  </div>
  
</div>







          <section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">Here are the things we do</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">Customers can take advantage of the following services through Arif Web .</p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <div class="grid-discover grid-cols-1 md:lg:xl:grid-cols-3  ">


<div
    class="p-10 flex flex-col items-center grid-temp text-center group hover:bg-slate-50 cursor-pointer">
    <span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FTeam%20work-rafiki.svg?alt=media&token=1afde778-c868-47e5-a557-6990235c5a82" alt="" />
        
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black'}} >Modern Website Design and Development</p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"11px"}}>Provide custom, responsive, and visually appealing website designs that are optimized for all devices</p>
</div>

<div
    class="grid-temp p-10 flex flex-col items-center text-center group hover:bg-slate-50 cursor-pointer">
<span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FContact%20us-pana.svg?alt=media&token=66dac90f-443e-40b2-81e9-8f203a712308" alt="" />
        
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black'}}>
    Social Media Integration </p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"11px"}}>Let us help you create a custom, responsive, and visually appealing website that elevates your brand and provides an exceptional user experience for your customers.</p>
</div>

<div class="grid-temp p-10 flex flex-col items-center text-center group   hover:bg-slate-50 cursor-pointer">
<span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FFast%20loading-rafiki.svg?alt=media&token=1563a9df-9ba6-4864-8d85-52f5aa1d185d" alt="" />
        
        </span>

    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black'}}>Website Hosting and Domain Registration</p>
    <p class="mt-2  text-slate-500" style={{fontSize:"11px"}}>we elevate your online presence by providing comprehensive hosting and domain registration services to support your business needs. </p>
</div>


<div class="grid-temp p-10 flex flex-col items-center text-center group    hover:bg-slate-50 cursor-pointer">

    <span class="p-5 rounded-full text-white ">
    <img className='h-32 w-32' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FFestivities-rafiki%20(1).svg?alt=media&token=7e47d90a-ed67-45ca-bd5c-44ec84aeafc5" alt="" />
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black'}}>
    Website Maintenance and Support
</p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"11px"}}>At our agency, we understand that the launch of a new website is just the beginning of your digital journey. That's why we offer a complimentary 2-month maintenance and support package to ensure your website's continued success.</p>
</div>

<div class="grid-temp p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
<span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FSecure%20data-rafiki.svg?alt=media&token=49a8a277-d1c5-4cee-a022-08beb19bb35d" alt="" />
        
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black'}}>
    Safe and secure</p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"11px"}}>Entrust your data to our secure management system, where your information is guaranteed to remain safe and protected. </p>
</div>

<div class="grid-temp p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
<span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FSavings-rafiki.svg?alt=media&token=8b1007cb-cdd8-4eef-8010-eb3e261a4a51" alt="" />
        
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black'}}>Affordable price </p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"11px"}}>Unlock the power of a professional online presence without draining your budget. Explore our affordable website packages and let us help you create a high-quality digital solution that fits your business perfectly.
    </p>
</div>




</div>
         </div>
    </div>
</section>
      
          <Footer/>
        </div>
        <div id='tablet'>
            <Nav component={'Home'}/>
   

            <main class="relative  flex  items-center justify-between  mb-20 " style={{height:'50vh'}}>
       
        <div style={{width:'40%'}}class=" pl-12  ">
            <div class=" mb-10">
      <h1
                class=" mt-24 font-extrabold tracking-tight text-gray-900  text-9xl" style={{fontSize:'6vw',lineHeight:'6.5vw',fontWeight:'1000'}}>
                <span class=" "><span class="mb-1 ">Local businesses </span>
               
                
                 can get <br />
                 world-class<br />
                </span>
                <div class="mt-2"> 
                    <span class="relative mt-3 whitespace-nowrap "><svg aria-hidden="true" viewBox="0 0 418 42"
                        class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                        preserveAspectRatio="none">
                        <path
                            d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
                        </path>
                    </svg>
                    <span class="relative"> <span class="mr-2  text-blue-600 ">
                    websites<br />
                </span> </span>
                    </span>
                </div>
                from us
            </h1>
        
            </div>
        <Link to='/contact'>
        <a class="bg-gray-900 rounded-xl text-white font-medium px-4 py-3 w-full mt-20 hover:bg-black/80 barlow" >I want a website →
        </a></Link>
      


        </div>
        <img style={{width:'50%'}} className='mt-20 -z-10' src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Website%20Creator-bro.svg?alt=media&token=421ad86e-6908-4b38-80eb-86af37993ecb" alt="" />
    
    </main>
    {/* <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
        backgroundColor: 'white', // Set the background color to white
      }}
    >
      <iframe
        src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Blue%20White%20UI%20Digitalism%20Music%20YouTube%20Intro.mp4?alt=media&token=0ef81930-1f74-4718-9926-073c9622fe19"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </div> */}
    <div
  className="swiper-wrapper "
 
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px',marginTop:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%', }}
  >
 <div style={{backgroundColor: "#0021db",marginTop:'200px'}} className='slide-case-studies-card w-full py-20'>
    <div className="ion-stacked-list__header glue-spacer-6-bottom">
      <h2 className="ion-stacked-list__headline glue-headline glue-headline--headline-3">
        <span className="glue-font-weight-medium">
          <h1  style={{fontSize:'50px',lineHeight:'55px',fontWeight:'900'}} className='font-extrabold tracking-tight text-white  text-9xl'>Let us build you a modern, fully coded website </h1>
        </span>
      </h2>
      <div className="glue-body--large ion-stacked-list__headline-text glue-spacer-3-top ">
        <p className='text-gray-100'>
        Our goal is to empower you with the knowledge and confidence to take control of your website, so you can focus on growing your business while we handle the technical aspects behind the scenes
        </p>
      </div>
      <div className="ion-stacked-list__container-cta glue-spacer-3-top">
    <Link to='/contact'>
    <a
        style={{backgroundColor:'white',color:'black'}}
          className="ion-button glue-button glue-button--high-emphasis"
        >
          Contact Us
        </a></Link>
      </div>
    </div>
    <div className="glue-grid">
      <div className="glue-grid__col glue-grid__col--span-1 glue-grid__col--span-0-lg" />
      <div className="glue-grid__col glue-grid__col--span-10 glue-grid__col--span-12-lg">
        <ul className="ion-stacked-list__list-grid">
          <li>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>1</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize: 25,color:'white' }}> Think</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'12px',lineHeight:'16px'}}>
              Choose a website type that suits your needs
              </p>
            </div>
          </li>
          <li>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>2</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize: 25,color:'white' }}>Let Us Know</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'12px',lineHeight:'16px'}}>
              Describe your idea to us and we will get to work building your website
              </p>
            </div>
          </li><li>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>3</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize: 25,color:'white' }}>Get your website</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'12px',lineHeight:'16px'}}>
              Get your website up and running by finalizing the payment
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
   
  </div>
 
  
</div>
<div style={{height:'50px'}}>
  
</div>
<div class="px-4 mx-auto text-center lg:px-36" style={{marginBottom:'200px',marginTop:'200px'}}>
            <span class="font-semibold text-gray-400 uppercase">Our Technology Stack</span>
          
       
            <div class="flex  justify-evenly items-center mt-8 text-gray-500  w-full">
            <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-20"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/react_original_wordmark_logo_icon_146375.png?alt=media&token=61aef2b6-fbc4-44f5-b181-ebc0f3d63d96" alt="" />                      
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-10"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/1706452773google-bard-logo-png.png?alt=media&token=5db19b25-00f5-44c2-ac4d-f5a48c47c8cd" alt="" />                      
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-12"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Primary_Horizontal_Lockup_Full_Color.png?alt=media&token=1809bf09-4354-468b-9e8f-7ea1217faa2f" alt="" />                      
                </div>
                <div class="mr-5 mb-5 lg:mb-0 ">
                <img class="h-12" src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/1_2STZmGfuaBZEACgUTTJzeg.webp?alt=media&token=33c71741-efa7-4393-a40f-b7bdd96b5c78" alt="Powered by AWS Cloud Computing"/>
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-20"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/tailwindcss_logo_icon_170649.png?alt=media&token=85e2a1eb-7f61-4680-b332-bb7232125bb0" alt="" />                      
                </div>      
            </div>
        </div>
        <div className="flex image-content-block_flexRowReverse__9cgdW" style={{marginBottom:'200px'}}>
    <div
    className="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
    style={{ backgroundColor: "#0021db",width:'50%',height:''  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU px-5 ">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        style={{ color: "#ffffff" }}
      >
          <h1  style={{fontSize:'40px',lineHeight:'45px',fontWeight:'900'}} className='font-extrabold tracking-tight text-white  text-9xl'>
        Easily track your data with your new multi-user fully coded website
        </h1>
      </h2>
<Link to='/contact'>

<a class="mt-5 barlow inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white border-white border rounded-2xl sm:w-auto sm:mb-0">
                    Book a call
                </a></Link>
    </div>
  </div>

  <div
    className=""
    style={{ backgroundColor: "#0021db",width:'50%',maxHeight:'100%' }}
  >
    <img
      alt="Our Dashboard"
      className="block"
      src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(9).png?alt=media&token=9a22b0ee-5dfe-4d66-b9fa-1b92408c1daf"
    />
  </div>

</div>
    
   
  


           

<div className="flex image-content-block_flexRowReverse__9cgdW" style={{marginBottom:'200px'}}>
<img class="rounded-[2rem] overflow-hidden  bg-white " style={{width:'40%',objectFit:'cover'}} src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(10).png?alt=media&token=89dfb95d-d091-44ba-8080-a797beda9b3b"  alt=""/>
    
    <div
    className="py5 flex items-center  md-size6of12 textBlockGutter"
    style={{width:'50%',height:''  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        style={{ color: "black" }}
      >
          <span  style={{fontSize:'40px',lineHeight:'45px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-900  text-9xl'>

        Your website data is instantly accessible on your phone  
        </span>
        
<p class="my-4 text-lg text-gray-500">Start developing with an open-source library of over 450+ UI components, sections, and pages built with the utility classes from Tailwind CSS and designed in Figma.</p>
<Link to='/services'>
<a class="inline-flex items-center text-lg text-blue-600 dark:text-blue-500 hover:underline">
Read more
<svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg>
</a>
</Link>
      </h2>

    </div>
  </div>


 


</div>
<div
    className="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
    style={{ backgroundColor: "#0021db",width:'100%',height:'90vh',marginBottom:'200px'  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        style={{ color: "#ffffff" }}
      >
          <span  style={{fontSize:'60px',lineHeight:'65px',fontWeight:'900'}} className='font-extrabold tracking-tight text-white  text-9xl'>

        We build stunning websites for businesses
        </span>
      </h2>

     <Link to='/contact'>
     <a class="mt-5 barlow inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white border-white border rounded-2xl sm:w-auto sm:mb-0">
                    Book a call
                </a></Link>
    </div>
  </div>



<div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="title-holder-align-bottom">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        Featured&nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        projects
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
  <Link to='/contact'>

    <div
      data-w-id="79057d9c-d2cf-dc34-a67a-c1fe0bc1a30a"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      
      
      <div
        className="btn-more-text btn-more-text-home-image flex"
        style={{
          fontSize:'11px',
          display:'flex',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        Contact Us
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>

    </div>

  </Link>
   
  </div>
  </div>

<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)" }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <a href="https://shewitpastry.shop" target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
          <h1  style={{color:'white',fontSize:'40px',lineHeight:'45px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-900  text-9xl'>
         
         Shewit Pastry</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'14px',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            We built a fully functional cake pastry website with an integrated ordering system 
            </p>
          </div>
        </div>
        <div
          className="holder"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(12)124-Photoroom.png?alt=media&token=77f71842-b26f-45b7-aea6-21851f7c4aa2"
            loading="eager"
            alt=""
            width="500px"
            style={{minWidth:'450px',objectFit:'contain'}}
          />
         
        </div>
      </div>
    </a>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <a href="https://keshtacademy.com" target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="">
          <h1  style={{color:'white',fontSize:'40px',lineHeight:'45px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-900  text-9xl'>
         
         Kesht Academy</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'14px',
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            By combining our technical expertise, creative vision, and collaborative approach, we've successfully delivered a tailored eLearning solution that empowers our clients to engage their audiences, enhance learning outcomes, and stay ahead of the curve in the dynamic world of online education.
            </p>
          </div>
        </div>
        <div
          className="holder"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(15)8.png?alt=media&token=6e6efa54-615c-4855-8c34-557966b3e61f"
            loading="eager"
            alt=""
            style={{minWidth:'450px',objectFit:'contain'}}
          />
         
        </div>
      </div>
    </a>
  </div>
  
</div>







          <section class="bg-white mt-20" style={{marginTop:'80px'}}>
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">Here are the things we do</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">Customers can take advantage of the following services through Arif Web .</p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <div class="grid-discover grid-cols-1 md:lg:xl:grid-cols-3  ">


<div
    class="p-10 flex flex-col items-center grid-temp text-center group hover:bg-slate-50 cursor-pointer">
    <span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FTeam%20work-rafiki.svg?alt=media&token=1afde778-c868-47e5-a557-6990235c5a82" alt="" />
        
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black',fontSize:'15px',lineHeight:'16px'}} >Modern Website Design and Development</p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"12px",lineHeight:'13px'}}>Provide custom, responsive, and visually appealing website designs that are optimized for all devices</p>
</div>

<div
    class="grid-temp p-10 flex flex-col items-center text-center group hover:bg-slate-50 cursor-pointer">
<span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FContact%20us-pana.svg?alt=media&token=66dac90f-443e-40b2-81e9-8f203a712308" alt="" />
        
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black',fontSize:'15px',lineHeight:'16px'}}>
    Social Media Integration </p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"12px",lineHeight:'13px'}}>Let us help you create a custom, responsive, and visually appealing website that elevates your brand and provides an exceptional user experience for your customers.</p>
</div>

<div class="grid-temp p-10 flex flex-col items-center text-center group   hover:bg-slate-50 cursor-pointer">
<span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FFast%20loading-rafiki.svg?alt=media&token=1563a9df-9ba6-4864-8d85-52f5aa1d185d" alt="" />
        
        </span>

    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black',fontSize:'15px',lineHeight:'16px'}}>Website Hosting and Domain Registration</p>
    <p class="mt-2  text-slate-500" style={{fontSize:"12px",lineHeight:'13px'}}>we elevate your online presence by providing comprehensive hosting and domain registration services to support your business needs. </p>
</div>


<div class="grid-temp p-10 flex flex-col items-center text-center group    hover:bg-slate-50 cursor-pointer">

    <span class="p-5 rounded-full text-white ">
    <img className='h-32 w-32' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FFestivities-rafiki%20(1).svg?alt=media&token=7e47d90a-ed67-45ca-bd5c-44ec84aeafc5" alt="" />
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black',fontSize:'15px',lineHeight:'16px'}}>
    Website Maintenance and Support
</p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"12px",lineHeight:'13px'}}>At our agency, we understand that the launch of a new website is just the beginning of your digital journey. That's why we offer a complimentary 2-month maintenance and support package to ensure your website's continued success.</p>
</div>

<div class="grid-temp p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
<span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FSecure%20data-rafiki.svg?alt=media&token=49a8a277-d1c5-4cee-a022-08beb19bb35d" alt="" />
        
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black',fontSize:'15px',lineHeight:'16px'}}>
    Safe and secure</p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"12px",lineHeight:'13px'}}>Entrust your data to our secure management system, where your information is guaranteed to remain safe and protected. </p>
</div>

<div class="grid-temp p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
<span class="p-5 rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FSavings-rafiki.svg?alt=media&token=8b1007cb-cdd8-4eef-8010-eb3e261a4a51" alt="" />
        
        </span>
    <p class="text-xl  text-slate-900 mt-3 btn-more-text btn-more-text-home-imag " style={{color:'black',fontSize:'15px',lineHeight:'16px'}}>Affordable price </p>
    <p class="mt-2  text-slate-500 normal-case borel" style={{fontSize:"12px",lineHeight:'13px'}}>Unlock the power of a professional online presence without draining your budget. Explore our affordable website packages and let us help you create a high-quality digital solution that fits your business perfectly.
    </p>
</div>




</div>
         </div>
    </div>
</section>
      
          <Footer/>
        </div>

        <div id='mobile'>
            <Nav component={'Home'}/>
   

            <main class="relative  flex items-center  " style={{height:'50vh'}}>
       
        <div style={{width:'100%',paddingLeft:'10vw'}}class="   ">
            <div class=" mb-10">
      <h1
                class="text-4xl mt-24 font-extrabold tracking-tight text-gray-900  sm:text-5xl md:text-6xl" style={{fontSize:'10vw',lineHeight:'11vw',fontWeight:'900'}}>
                <span class=" "><span class="mb-1 ">Local businesses </span>
               
                
                 can get <br /> world-class
                </span>
                <div class="mt-2"> 
                    <span class="relative mt-3 whitespace-nowrap "><svg aria-hidden="true" viewBox="0 0 418 42"
                        class="absolute top-3/4 left-0 right-0 m-auto h-[0.58em] w-fit fill-pink-400/50"
                        preserveAspectRatio="none">
                        <path
                            d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
                        </path>
                    </svg>
                    <span class="relative"> <span class="mr-2  text-blue-600 ">
                    websites
                </span> from us</span>
                    </span>
                </div>
                
            </h1>
        
            </div>
    <Link to='/contact'>

            <a class="bg-gray-900 rounded-xl text-white font-medium px-4 py-3 w-full mt-20 hover:bg-black/80 barlow" >I want a website →
    </a>
      </Link>


        </div>
    
    </main>
    {/* <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
        backgroundColor: 'white', // Set the background color to white
      }}
    >
      <iframe
        src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Blue%20White%20UI%20Digitalism%20Music%20YouTube%20Intro.mp4?alt=media&token=0ef81930-1f74-4718-9926-073c9622fe19"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </div> */}
    <div
  className="swiper-wrapper "
 
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'10vw',marginTop:'10vw',overflow:'hidden' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%', }}
  >
 <div style={{backgroundColor: "#0021db",marginTop:'200px'}} className='slide-case-studies-card w-full py-20'>
    <div className="ion-stacked-list__header glue-spacer-6-bottom">
      <h2 className="ion-stacked-list__headline glue-headline glue-headline--headline-3">
        <span className="glue-font-weight-medium">
          <span style={{ fontSize: '8vw',color:'white',lineHeight:'8.3vw' }} className='text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl'>Let us build you a modern, fully coded website </span>
        </span>
      </h2>
      <div className="glue-body--large ion-stacked-list__headline-text glue-spacer-3-top ">
        <p className='text-gray-100' style={{fontSize:'3vw',lineHeight:'3.5vw'}}>
        Our goal is to empower you with the knowledge and confidence to take control of your website, so you can focus on growing your business while we handle the technical aspects behind the scenes
        </p>
      </div>
      <div className="ion-stacked-list__container-cta glue-spacer-3-top">
    <Link to='/contact'>
    <a
        style={{color:'white',backgroundColor:'transparent'}}
          className="ion-button glue-button glue-button--high-emphasis border"
        >
          Contact Us
        </a></Link>
      </div>
    </div>
    <ul className="flex flex-col items-center text-center">
          <li style={{minWidth:'300px',marginBottom:'20vw'}}>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>1</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize:'4vw',color:'white' }}> Think</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'3vw',lineHeight:'16px'}}>
              Choose a website type that suits your needs
              </p>
            </div>
          </li>
          <li style={{minWidth:'300px',marginBottom:'20vw'}}>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>2</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize: '4vw',color:'white' }}>Let Us Know</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'3vw',lineHeight:'16px'}}>
              Describe your idea to us and we will get to work building your website
              </p>
            </div>
          </li><li style={{minWidth:'300px',marginBottom:'20vw'}}>
            <div className="ion-stacked-list__container-image glue-spacer-3-bottom glue-grid__col--span-6-sm">
              <div className="ion-stacked-list__container-image--media">
                <div className="bg-white rounded-full mx-auto flex justify-center items-center" style={{width:'80px',height:'80px'}}>
           
                  <h1 className=''style={{fontSize:'40px'}}>3</h1>
               
                </div>
              </div>
            </div>
            <div className="ion-stacked-list__container-copy glue-grid__col--span-4-sm mt-5">
              <h2 className="glue-headline--headline-4 glue-spacer-1-bottom ">
                <span className="glue-font-weight-medium barlow">
                  <span style={{ fontSize: '4vw',color:'white' }}>Get your website</span>
                </span>
              </h2>
              <p className='par text-white ' style={{fontSize:'3vw',lineHeight:'16px'}}>
              Get your website up and running by finalizing the payment
              </p>
            </div>
          </li>
        </ul>
</div>
   
  </div>
 
  
</div>
<div style={{height:'50px'}}>
  
</div>
<div class="px-4 mx-auto text-center lg:px-36" style={{marginBottom:'10vw',marginTop:'10vw'}}>
            <span class="font-semibold text-gray-400 uppercase" style={{fontSize:'3vw'}}>Our Technology Stack</span>
          
       
            <div class="flex  justify-evenly items-center mt-8 text-gray-500  w-full">
          
                <div class="mr-5 mb-5 lg:mb-0 ">
                <img class="h-10" src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/1_2STZmGfuaBZEACgUTTJzeg.webp?alt=media&token=33c71741-efa7-4393-a40f-b7bdd96b5c78" alt="Powered by AWS Cloud Computing"/>
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-16"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/tailwindcss_logo_icon_170649.png?alt=media&token=85e2a1eb-7f61-4680-b332-bb7232125bb0" alt="" />                      
                </div>      
            </div>
            <div class="flex  justify-evenly items-center mt-8 text-gray-500  w-full">
            <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-16"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/react_original_wordmark_logo_icon_146375.png?alt=media&token=61aef2b6-fbc4-44f5-b181-ebc0f3d63d96" alt="" />                      
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-8"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/1706452773google-bard-logo-png.png?alt=media&token=5db19b25-00f5-44c2-ac4d-f5a48c47c8cd" alt="" />                      
                </div> 
                <div class="mr-5 mb-5 lg:mb-0 ">
                 <img class="h-10"src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Primary_Horizontal_Lockup_Full_Color.png?alt=media&token=1809bf09-4354-468b-9e8f-7ea1217faa2f" alt="" />                      
                </div>
                 
            </div>
        </div>
        <div className="flex image-content-block_flexRowReverse__9cgdW flex-col" style={{marginBottom:'30vw'}}>
        <div
    className=""
    style={{ backgroundColor: "#0021db",width:'100%',maxHeight:'100%' }}
  >
    <img
      alt="Our Dashboard"
      className="block"
      src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(9).png?alt=media&token=9a22b0ee-5dfe-4d66-b9fa-1b92408c1daf"
    />
  </div>
    <div
    className="py5 flex items-center justify-center flex-col text-center md-size6of12 textBlockGutter"
    style={{ backgroundColor: "#0021db",width:'100%',height:'50vh'  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        
        style={{ color: "#ffffff" }}
      >
        <span style={{fontSize:'6vw',padding:'5vw'}} className="text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
        Easily track your data with your new multi-user fully coded website
        </span>
      </h2>
<Link to='/contact'>

<a style={{width:'30%'}} class="mt-5 barlow inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white border-white border rounded-2xl sm:w-auto sm:mb-0">
                    Book a call
                </a></Link>
    </div>
  </div>

 

</div>
    
   
  


           

<div className="flex image-content-block_flexRowReverse__9cgdW" style={{marginBottom:'200px'}}>
<img class="rounded-[2rem] overflow-hidden  bg-white " style={{width:'40%',objectFit:'cover'}} src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(10).png?alt=media&token=89dfb95d-d091-44ba-8080-a797beda9b3b"  alt=""/>
    
    <div
    className="py5 flex items-center  md-size6of12 textBlockGutter"
    style={{width:'50%',height:''  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        style={{ color: "black" }}
      >
        <span style={{fontSize:'7vw'}} className="text-4xl font-extrabold tracking-tight leading-none  md:text-5xl lg:text-6xl">
        Your website data is instantly accessible on your phone  
        </span>
        
<p class="my-4 text-lg text-gray-500"style={{fontSize:'3vw'}}>Start developing with an open-source library of over 450+ UI components, sections, and pages built with the utility classes from Tailwind CSS and designed in Figma.</p>
<Link to='/services'>
<a  class="inline-flex items-center text-lg text-blue-600  hover:underline">
Read more
<svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg>
</a>
</Link>

      </h2>

    </div>
  </div>


 


</div>
<div
    className="py5 flex items-center justify-center text-center md-size6of12 textBlockGutter"
    style={{ backgroundColor: "#0021db",width:'100%',height:'90vh',marginBottom:'20vw'  }}
  >
    <div className="image-content-block_copyBlockMaxWidth__qmXtU px-5">
      <h2
        className="sb-heading mb4 xl text-semibold"
        tabIndex={-1}
        style={{ color: "#ffffff",fontSize:'8vw',lineHeight:'8.5vw' }}
      >
        <span className="text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
        We build stunning websites for businesses
        </span>
      </h2>

     <Link to='/contact'class="mt-5 barlow inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white border-white border rounded-2xl sm:w-auto sm:mb-0" style={{width:'30%'}}>
                    Book a call
                </Link>
    </div>
  </div>



  <div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="flex justify-center">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          fontSize:'3vw',
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        Projects&nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          fontSize:'3vw',

          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
    <Link to='/contact'>
    <div
      data-w-id="flex justify-center"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      <div
        className="btn-more-text btn-more-text-home-image"
        style={{
          fontSize:'3vw',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        Contact Us
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          // loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>
    </div>
    </Link>

   
  </div>
</div>

<div
  className="swiper-wrapper flex-col "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)" }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto' }}
  >
    <a href="https://shewitpastry.shop" target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Shewit Pastry</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            We built a fully functional cake pastry website with an integrated ordering system 
            </p>
          </div>
        </div>
        <div
          className="holder"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(12)124-Photoroom.png?alt=media&token=77f71842-b26f-45b7-aea6-21851f7c4aa2"
            loading="eager"
            alt=""
            width="500px"
            style={{minWidth:'450px',objectFit:'contain'}}
          />
         
        </div>
      </div>
    </a>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto' }}
  >
    <a href="/shewitpastry" className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',
          marginTop:'30vw',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 style={{fontSize:'8vw'}} className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Kesht Academy</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'3vw',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            We built a fully functional cake pastry website with an integrated ordering system 
            </p>
          </div>
        </div>
        <div
          className="holder"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/Untitled%20design%20(15)8.png?alt=media&token=6e6efa54-615c-4855-8c34-557966b3e61f"
            loading="eager"
            alt=""
            style={{minWidth:'450px',objectFit:'contain'}}
          />
         
        </div>
      </div>
    </a>
  </div>
  
</div>







          <section class="bg-white" style={{marginBottom:'50vw'}}>
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 style={{fontSize:'8vw',marginTop:'50vw'}}class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">Here are the things we do</h1>
        <p style={{fontSize:'3vw'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 par">Customers can take advantage of the following services through Arif Web .</p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
        <div class="grid-discover grid-cols-1 md:lg:xl:grid-cols-3  ">


<div
style={{paddingLeft:'2vw',paddingRight:'2vw'}}
    class=" flex flex-col items-center  text-center group hover:bg-slate-50 cursor-pointer">
    <span style={{paddingLeft:'2vw',paddingRight:'2vw'}} class=" rounded-full  text-white ">
        <img class="h-32 w-32" sty src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FTeam%20work-rafiki.svg?alt=media&token=1afde778-c868-47e5-a557-6990235c5a82" alt="" />
        
        </span>
    <p class="text-xl  text-gray-900 mt-3 capitalize barlow" style={{fontSize:"3.2vw",lineHeight:'3.3vw'}}>Modern Website Design and Development</p>
    <p class="mt-2  text-gray-500 normal-case par" style={{fontSize:"2.2vw",lineHeight:"2.4vw"}}>Provide custom, responsive, and visually appealing website designs that are optimized for all devices</p>
</div>

<div
style={{paddingLeft:'2vw',paddingRight:'2vw'}}
    class="grid-temp  flex flex-col items-center text-center group hover:bg-slate-50 cursor-pointer">
<span style={{paddingLeft:'2vw',paddingRight:'2vw'}} class=" rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FContact%20us-pana.svg?alt=media&token=66dac90f-443e-40b2-81e9-8f203a712308" alt="" />
        
        </span>
    <p style={{fontSize:"3.2vw",lineHeight:'3.3vw'}} class="text-xl  text-gray-900 capitalize barlow mt-3">
    Social Media Integration </p>
    <p class="mt-2  text-gray-500 normal-case par"  style={{fontSize:"2.2vw",lineHeight:"2.4vw"}}>Let us help you create a custom, responsive, and visually appealing website that elevates your brand and provides an exceptional user experience for your customers.</p>
</div>

<div style={{paddingLeft:'2vw',paddingRight:'2vw'}} class="grid-temp flex flex-col items-center text-center group   hover:bg-slate-50 cursor-pointer">
<span style={{paddingLeft:'2vw',paddingRight:'2vw'}} class=" rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FFast%20loading-rafiki.svg?alt=media&token=1563a9df-9ba6-4864-8d85-52f5aa1d185d" alt="" />
        
        </span>

    <p class="text-xl  text-gray-900 capitalize barlow mt-3" style={{fontSize:"3.2vw",lineHeight:'3.3vw'}}>Website Hosting and Domain Registration</p>
    <p class="mt-2  text-gray-500 normal-case par"  style={{fontSize:"2.2vw",lineHeight:"2.4vw"}}>we elevate your online presence by providing comprehensive hosting and domain registration services to support your business needs.</p>
</div>


<div style={{paddingLeft:'2vw',paddingRight:'2vw'}}class="grid-temp  flex flex-col items-center text-center group    hover:bg-slate-50 cursor-pointer">

    <span class=" rounded-full text-white " style={{paddingLeft:'2vw',paddingRight:'2vw'}}>
    <img className='h-32 w-32' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FFestivities-rafiki%20(1).svg?alt=media&token=7e47d90a-ed67-45ca-bd5c-44ec84aeafc5" alt="" />
        </span>
    <p style={{fontSize:"3.2vw",lineHeight:'3.3vw'}} class="text-xl  text-gray-900 capitalize barlow mt-3">
    Website Maintenance and Support
</p>
    <p  style={{fontSize:"2.2vw",lineHeight:"2.4vw"}} class="mt-2  text-gray-500 normal-case par">At our agency, we understand that the launch of a new website is just the beginning of your digital journey. That's why we offer a complimentary 2-month maintenance and support package to ensure your website's continued success.</p>
</div>

<div class="grid-temp  flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
<span class=" rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FSecure%20data-rafiki.svg?alt=media&token=49a8a277-d1c5-4cee-a022-08beb19bb35d" alt="" />
        
        </span>
    <p style={{fontSize:"3.2vw",lineHeight:'3.3vw'}}class="text-xl  text-gray-900 capitalize barlow mt-3">
    Safe and secure</p>
    <p  style={{fontSize:"2.2vw",lineHeight:"2.4vw"}}class="mt-2  text-gray-500 normal-case par">Entrust your data to our secure management system, where your information is guaranteed to remain safe and protected.</p>
</div>

<div class="grid-temp  flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
<span class=" rounded-full  text-white ">
        <img class="h-32 w-32" src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FSavings-rafiki.svg?alt=media&token=8b1007cb-cdd8-4eef-8010-eb3e261a4a51" alt="" />
        
        </span>
    <p style={{fontSize:"3.2vw",lineHeight:'3.3vw'}}class="text-xl  text-gray-900 capitalize barlow mt-3">Affordable price </p>
    <p  style={{fontSize:"2.2vw",lineHeight:"2.4vw"}}class="mt-2  text-gray-500 normal-case par">Unlock the power of a professional online presence without draining your budget. Explore our affordable website packages and let us help you create a high-quality digital solution that fits your business perfectly.
    </p>
</div>




</div>
         </div>
    </div>
</section>
      
          <Footer/>
        </div>

    </div>
  )
}
