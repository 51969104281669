import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div>
        <div id="desktop">
            



        <footer class="bg-white rounded-lg shadow  m-4">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
           <Link to='/' className='flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse'>
           <img src='https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/21.png?alt=media&token=56a47e84-d455-4802-b9de-1e17292313fc'alt="Logo" className='ml-5' style={{width:'20%'}} />

            </Link>

            <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 ">
                <li>
                    <Link to="/about" class="hover:underline par  me-4 md:me-6">About us</Link>
                </li>
                <li>
                </li>
                <li>
                    <Link to="/services" class="hover:underline par me-4 md:me-6">Services</Link>
                </li>
                <li>
                    <Link to="/contact" class="hover:underline par">Contact</Link>
                </li>
            </ul>
        </div>
        <div class="my-6  sm:mx-auto  lg:my-8" ></div>
        <div className='flex justify-between'>
        <span class="block text-sm text-gray-500 sm:text-center ">© 2024 <Link href="https://flowbite.com/" class="hover:underline par">Arif Web™</Link>. All Rights Reserved.</span>
        
        <div class="flex mt-4 sm:justify-center sm:mt-0">
              <a href="https://www.facebook.com/profile.php?id=61560968776960" target='_blank' class="text-gray-500 hover:text-gray-900 ">
                  <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
              <a href="https://www.linkedin.com/company/arifweb/" target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
               
              <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-linkedin w-4 h-4" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                  <span class="sr-only">Linkdin</span>
              </a>
              <a href="https://www.instagram.com/arifweb11" target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
              
                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-instagram w-4 h-4" viewBox="0 0 16 16">
  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
</svg>
                  <span class="sr-only">Instagram</span>
              </a>
              <a href="https://t.me/dawa_sonny"target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
                
                  <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-telegram" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
                  <span class="sr-only">Telegram</span>
              </a>
         
          </div>
        </div>
    
    </div>
</footer>



        </div>
            



        <footer id="tablet" class="bg-white rounded-lg shadow  m-4">
    <div class="w-full l mx-auto p-4 md:py-8">
        <div class="flex items-center justify-between ">
           <Link to='/' className='flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse'>
           <img src='https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/21.png?alt=media&token=56a47e84-d455-4802-b9de-1e17292313fc'alt="Logo" className='ml-5' style={{width:'20%'}} />
            
            </Link>

            <ul class="flex flex-wrap items-center  text-sm font-medium text-gray-500 sm:mb-0 ">
                <li>
                    <Link to="/about" class="hover:underline barlow me-4 md:me-6">About us</Link>
                </li>
                <li>
                </li>
                <li>
                    <Link to="/services" class="hover:underline barlow me-4 md:me-6">Services</Link>
                </li>
                <li>
                    <Link to="/contact" class="hover:underline barlow">Contact</Link>
                </li>
            </ul>
        </div>
        <div class="my-6  sm:mx-auto  " ></div>
        <div className='flex justify-between mt-6'>
        <span class="block text-sm text-gray-500 sm:text-center barlow">© 2024 <Link href="https://flowbite.com/" class="hover:underline par">Arif Web™</Link>. All Rights Reserved.</span>

        <div class="flex mt-4 sm:justify-center sm:mt-0">
              <a href="https://www.facebook.com/profile.php?id=61560968776960" target='_blank' class="text-gray-500 hover:text-gray-900 ">
                  <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
              <a href="https://www.linkedin.com/company/arifweb/" target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
               
              <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-linkedin w-4 h-4" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                  <span class="sr-only">Linkdin</span>
              </a>
              <a href="https://www.instagram.com/arifweb11" target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
              
                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-instagram w-4 h-4" viewBox="0 0 16 16">
  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
</svg>
                  <span class="sr-only">Instagram</span>
              </a>
              <a href="https://t.me/dawa_sonny"target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
                
                  <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-telegram" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
                  <span class="sr-only">Telegram</span>
              </a>
         
          </div>
        </div>
    
    </div>
</footer>




        <div id="mobile">
            



            <footer class="bg-white rounded-lg shadow  m-4">
        <div class="w-full  mx-auto p-4 md:py-8">
            <div class="flex items-center justify-between mb-12">
               <Link style={{width:'150%'}} to='/' className='flex items-center mb-4 sm:mb-0 '>
           <img src='https://firebasestorage.googleapis.com/v0/b/webethiopia-9c91a.appspot.com/o/21.png?alt=media&token=56a47e84-d455-4802-b9de-1e17292313fc'alt="Logo" className='ml-5' style={{width:'20%'}} />
                
                </Link>
    
                <ul class="flex items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 ">
                    <li>
                        <Link to="/about" class="hover:underline par  " style={{fontSize:'2.8vw',margin:'2vw',wordBreak:'keep-all' }}>About </Link>
                    </li>
                  
                    <li>
                        <Link to="/services" class="hover:underline par " style={{fontSize:'2.8vw',margin:'2vw' }}>services</Link>
                    </li>
                    <li>
                        <Link to="/contact" class="hover:underline par" style={{fontSize:'2.8vw',margin:'2vw' }}>Contact</Link>
                    </li>
                </ul>
            </div>
            <div class="my-6  sm:mx-auto  " ></div>
            <div className='flex justify-between items-center'>
            <span class="block text-sm text-gray-500 sm:text-center " style={{fontSize:'2.5vw'}}>© 2024 <Link href="https://flowbite.com/" class="hover:underline par">Arif Web™</Link>. All Rights Reserved.</span>
    
            <div class="flex  sm:justify-center sm:mt-0">
                  <a href="https://www.facebook.com/profile.php?id=61560968776960" target='_blank' class="text-gray-500 hover:text-gray-900 ">
                      <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                            <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                        </svg>
                      <span class="sr-only">Facebook page</span>
                  </a>
                  <a href="https://www.linkedin.com/company/arifweb/" target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
                   
                  <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-linkedin w-4 h-4" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                      <span class="sr-only">Linkdin</span>
                  </a>
                  <a href="https://www.instagram.com/arifweb11" target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
                  
                    <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-instagram w-4 h-4" viewBox="0 0 16 16">
      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
    </svg>
                      <span class="sr-only">Instagram</span>
                  </a>
                  <a href="https://t.me/dawa_sonny"target='_blank' class="text-gray-500 hover:text-gray-900  ms-5">
                    
                      <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-telegram" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
    </svg>
                      <span class="sr-only">Telegram</span>
                  </a>
             
              </div>
            </div>
        
        </div>
    </footer>
    
    
    
            </div>
    </div>
  )
}
