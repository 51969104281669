import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';import Home from './No Auth/home';
import Aboutus from './No Auth/aboutus';
import Contact from './No Auth/contact';
import Services from './No Auth/services';
import NotFound from './No Auth/NotFound';


function App() {
  return (

      <BrowserRouter>

    <Routes>
 

        {/* Home */}
        <Route  element={<Home/>} path="/"/>
        {/* <Route  element={<Estimate/>} path="/Estimate"/> */}
        <Route  element={<Aboutus/>} path="/about"/>
        <Route  element={<Contact/>} path="/contact"/>
        <Route  element={<Services/>} path="/services"/>
        <Route path="*" element={<NotFound />} />


        





       
      
        
     
      </Routes>
      </BrowserRouter>
  );
}

export default App;
