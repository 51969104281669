import React from 'react'
import Nav from './nav'
import Footer from './footer'
import { Link } from 'react-router-dom'

export default function Aboutus() {
  return (
    <div>
        <div id='desktop'>
            <Nav component={'About Us'}/>
            <section class="">
    <div class="py-12 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            
          <section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">We'd like to introduce ourselves briefly</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16  par">We are an Ethiopia-based web agency working with clients worldwide. Our goal as passionate developers is to build products that are engaging, accessible, and easy to use for businesses. We are committed to providing the highest quality services and delivering results that are tailored to our clients' needs. Our team of experienced developers is committed to delivering the best technology and solutions. We strive to create a positive impact in our clients' businesses. We use the latest technology to ensure our clients have a competitive edge. We are committed to providing the highest quality of service and ensuring that our clients' needs are met. We strive to provide the best possible results.
        </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         {/* <Link to='/courseprogram'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2">Enroll Now</button> 

         </Link> */}
         </div>
    </div>
</section>


        </div>
    </div>
</section>

            <div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%', }}
  >
 <div style={{backgroundColor: "#0021db",marginBottom:'50px',paddingTop:'100px'}} className='slide-case-studies-card w-full py-20'>

<div class="lg:text-center" >
    <h2
        class=" font-heading mb-4 bg-white text-orange-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
        Why choose us?
    </h2>
    <p class="mb-10 text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl">
    Our experience in modern web technology can help your business grow digitally 
    </p>
</div>
<div class="mt-20">
    <dl class="flex justify-evenly">
        <div class="relative"style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-code-slash h-12 w-12" viewBox="0 0 16 16">
<path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0m6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Fully Coded Website</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par">
            Once we've completed the development of your fully coded website, we'll provide you with convenient access to the website files and code. So that you'll have the freedom to make updates, enhancements, or even migrate the site to a different hosting provider in the future, if needed
            </dd>
        </div>
        <div class="relative" style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center  rounded-md bg-primary-500 text-white">
                   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-google h-12 w-12" viewBox="0 0 16 16">
<path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Integrate Google Business with your website
                </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par"> If your company doesn’t already have a google business profile, our team will handle the entire process of creating or integrating your Google Business Profile, ensuring your online visibility is maximized from the start. 
            </dd>
        </div>
   
    </dl>
    
</div>
<div class="mt-10">
    <dl class="flex justify-evenly">
        <div class="relative"style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-hdd-network h-12 w-12" viewBox="0 0 16 16">
<path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Manage your website once it's up and running</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par">
            Our goal is to empower you with the knowledge and confidence to take control of your website. After the initial training, our team offers a 2-month free maintenance and support to answer any questions, provide troubleshooting assistance, and ensure your website continues to serve your business needs effectively.
            </dd>
        </div>
        <div class="relative" style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center  rounded-md bg-primary-500 text-white">
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-bag-check h-12 w-12" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50"> E-commerce Solutions
                </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par"> Our team designs and develops a robust e-commerce platform that provides a seamless shopping experience for customers by integrating secure payment gateways, inventory management systems, and optimizing e-commerce platform for search engines and mobile devices to maximize online sales.
            </dd>
        </div>
   
    </dl>
    
</div>
</div>
   
  </div>
 
  
</div>

<div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="title-holder-align-bottom">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        What we do for &nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        companies
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
    <Link to='/contact'>
    <div
      data-w-id="79057d9c-d2cf-dc34-a67a-c1fe0bc1a30a"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      <div
        className="btn-more-text btn-more-text-home-image"
        style={{
          fontSize:'11px',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        Contact Us
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>
    </div>
    </Link>

   
  </div>
</div>

<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>We build websites for businesses from scratch</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
          We design our website according to the client's preferences. Initially, we design your website on Figma and make sure the client is fully on board before converting the code to a real website. React is our preferred framework for building web applications and Tailwind CSS is used for designing user-friendly interfaces. Our back-end is powered by Firebase/AWS Amplify. 

Once your website is complete, we will hand over the code to our clients.Depending on the project scope, a website can be made for as little as 20k. All domain and hosting costs will be covered by us for the first year. In addition, you will be responsible for paying for the backend service, which is initially free until you pass the backend provider's limit (Firebase or AWS Amplify). After the backend limit is exceeded, you will be responsible for paying the backend service fees directly to the provider. The fees vary depending on the number of active users and amount of data stored. We can also provide you with an estimate for these costs if needed.
            </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Connect businesses' websites with Google My Business and other social media</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
                       Google My Business is a free tool that allows businesses to manage their online presence on Google, including how they appear in Google Search and Google Maps. By connecting the client's website to their Google My Business profile, we ensure that crucial information like the business name, address, phone number, and website are consistent and up-to-date across Google's search results.


We also integrate the client's website with their active social media accounts, such as Facebook, Twitter, Instagram, LinkedIn, and others.This allows the website to display social media content (like posts and reviews) and provide easy access for visitors to follow and engage with the business on these platforms.

The key benefits of these integrations include Consistent Online Presence, Social Media Connectivity, Enhanced Engagement, and Streamlined Management.

By connecting our clients' websites with these important digital platforms, we help them expand their online reach, improve their credibility, and ultimately, attract more customers to their business. It's a win-win for our clients and their customers!
 
            </p>
          </div>
        </div>
      
      </div>
    </div>
  </div>
  
</div>

        </div>
        <div id='tablet'>
            <Nav component={'About Us'}/>
            <section class="">
    <div class="py-12 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            
          <section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
      
        <h1 class="mb-4  font-extrabold tracking-tight leading-none text-gray-900 text-5xl lg:text-6xl ">We'd like to introduce ourselves briefly</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16  par">We are an Ethiopia-based web agency working with clients worldwide. Our goal as passionate developers is to build products that are engaging, accessible, and easy to use for businesses. We are committed to providing the highest quality services and delivering results that are tailored to our clients' needs. Our team of experienced developers is committed to delivering the best technology and solutions. We strive to create a positive impact in our clients' businesses. We use the latest technology to ensure our clients have a competitive edge. We are committed to providing the highest quality of service and ensuring that our clients' needs are met. We strive to provide the best possible results.
        </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         {/* <Link to='/courseprogram'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2">Enroll Now</button> 

         </Link> */}
         </div>
    </div>
</section>


        </div>
    </div>
</section>

            <div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '100%', }}
  >
 <div style={{backgroundColor: "#0021db",marginBottom:'50px',paddingTop:'100px'}} className='slide-case-studies-card w-full py-20'>

<div class="lg:text-center" >
    <h2
        class=" font-heading mb-4 bg-white text-orange-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
        Why choose us?
    </h2>
    <p class="mb-10  font-extrabold leading-none tracking-tight text-white text-5xl lg:text-6xl">
    Our experience in modern web technology can help your business grow digitally 
    </p>
</div>
<div class="mt-20">
    <dl class="flex justify-evenly">
        <div class="relative"style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-code-slash h-12 w-12" viewBox="0 0 16 16">
<path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0m6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Fully Coded Website</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par">
            Once we've completed the development of your fully coded website, we'll provide you with convenient access to the website files and code. So that you'll have the freedom to make updates, enhancements, or even migrate the site to a different hosting provider in the future, if needed
            </dd>
        </div>
        <div class="relative" style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center  rounded-md bg-primary-500 text-white">
                   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-google h-12 w-12" viewBox="0 0 16 16">
<path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Integrate Google Business with your website
                </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par"> If your company doesn’t already have a google business profile, our team will handle the entire process of creating or integrating your Google Business Profile, ensuring your online visibility is maximized from the start. 
            </dd>
        </div>
   
    </dl>
    
</div>
<div class="mt-10">
    <dl class="flex justify-evenly">
        <div class="relative"style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-hdd-network h-12 w-12" viewBox="0 0 16 16">
<path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Manage your website once it's up and running</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par">
            Our goal is to empower you with the knowledge and confidence to take control of your website. After the initial training, our team offers a 2-month free maintenance and support to answer any questions, provide troubleshooting assistance, and ensure your website continues to serve your business needs effectively.
            </dd>
        </div>
        <div class="relative" style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center  rounded-md bg-primary-500 text-white">
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-bag-check h-12 w-12" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50"> E-commerce Solutions
                </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par"> Our team designs and develops a robust e-commerce platform that provides a seamless shopping experience for customers by integrating secure payment gateways, inventory management systems, and optimizing e-commerce platform for search engines and mobile devices to maximize online sales.
            </dd>
        </div>
   
    </dl>
    
</div>
</div>
   
  </div>
 
  
</div>

<div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="title-holder-align-bottom">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        What we do for &nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        companies
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
    <Link to='/contact'>
    <div
      data-w-id="79057d9c-d2cf-dc34-a67a-c1fe0bc1a30a"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      <div
        className="btn-more-text btn-more-text-home-image"
        style={{
          fontSize:'11px',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        Contact Us
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>
    </div>
    </Link>

   
  </div>
</div>

<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>We build websites for businesses from scratch</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
          We design our website according to the client's preferences. Initially, we design your website on Figma and make sure the client is fully on board before converting the code to a real website. React is our preferred framework for building web applications and Tailwind CSS is used for designing user-friendly interfaces. Our back-end is powered by Firebase/AWS Amplify. 

Once your website is complete, we will hand over the code to our clients.Depending on the project scope, a website can be made for as little as 20k. All domain and hosting costs will be covered by us for the first year. In addition, you will be responsible for paying for the backend service, which is initially free until you pass the backend provider's limit (Firebase or AWS Amplify). After the backend limit is exceeded, you will be responsible for paying the backend service fees directly to the provider. The fees vary depending on the number of active users and amount of data stored. We can also provide you with an estimate for these costs if needed.
            </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Connect businesses' websites with Google My Business and other social media</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            Google My Business is a free tool that allows businesses to manage their online presence on Google, including how they appear in Google Search and Google Maps. By connecting the client's website to their Google My Business profile, we ensure that crucial information like the business name, address, phone number, and website are consistent and up-to-date across Google's search results.


We also integrate the client's website with their active social media accounts, such as Facebook, Twitter, Instagram, LinkedIn, and others.This allows the website to display social media content (like posts and reviews) and provide easy access for visitors to follow and engage with the business on these platforms.

The key benefits of these integrations include Consistent Online Presence, Social Media Connectivity, Enhanced Engagement, and Streamlined Management.

By connecting our clients' websites with these important digital platforms, we help them expand their online reach, improve their credibility, and ultimately, attract more customers to their business. It's a win-win for our clients and their customers!
            </p>
          </div>
        </div>
      
      </div>
    </div>
  </div>
  
</div>

        </div>
        <div id='mobile'>
            <Nav component={'About Us'}/>
            <section class="">
    <div class="py-12 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            
          <section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center ">
      
        <h1 class="mb-4  font-extrabold tracking-tight leading-none text-gray-900 text-5xl lg:text-6xl ">We'd like to introduce ourselves briefly</h1>
        <p style={{fontSize:'13px'}} class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16  par">We are an Ethiopia-based web agency working with clients worldwide. Our goal as passionate developers is to build products that are engaging, accessible, and easy to use for businesses. We are committed to providing the highest quality services and delivering results that are tailored to our clients' needs. Our team of experienced developers is committed to delivering the best technology and solutions. We strive to create a positive impact in our clients' businesses. We use the latest technology to ensure our clients have a competitive edge. We are committed to providing the highest quality of service and ensuring that our clients' needs are met. We strive to provide the best possible results.
        </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
         {/* <Link to='/courseprogram'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2">Enroll Now</button> 

         </Link> */}
         </div>
    </div>
</section>


        </div>
    </div>
</section>

            <div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '100%', }}
  >
 <div style={{backgroundColor: "#0021db",marginBottom:'50px',paddingTop:'100px'}} className='slide-case-studies-card w-full py-20'>

<div class="lg:text-center" >
    <h2
        class=" font-heading mb-4 bg-white text-orange-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
        Why choose us?
    </h2>
    <p class="mb-10  font-extrabold leading-none tracking-tight text-white text-5xl lg:text-6xl">
    Our experience in modern web technology can help your business grow digitally 
    </p>
</div>
<div class="mt-20">
    <dl class="flex justify-evenly">
        <div class="relative"style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-code-slash h-12 w-12" viewBox="0 0 16 16">
<path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0m6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Fully Coded Website</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par">
            Once we've completed the development of your fully coded website, we'll provide you with convenient access to the website files and code. So that you'll have the freedom to make updates, enhancements, or even migrate the site to a different hosting provider in the future, if needed
            </dd>
        </div>
        <div class="relative" style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center  rounded-md bg-primary-500 text-white">
                   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-google h-12 w-12" viewBox="0 0 16 16">
<path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Integrate Google Business with your website
                </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par"> If your company doesn’t already have a google business profile, our team will handle the entire process of creating or integrating your Google Business Profile, ensuring your online visibility is maximized from the start. 
            </dd>
        </div>
   
    </dl>
    
</div>
<div class="mt-10">
    <dl class="flex justify-evenly">
        <div class="relative"style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-hdd-network h-12 w-12" viewBox="0 0 16 16">
<path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50">Manage your website once it's up and running</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par">
            Our goal is to empower you with the knowledge and confidence to take control of your website. After the initial training, our team offers a 2-month free maintenance and support to answer any questions, provide troubleshooting assistance, and ensure your website continues to serve your business needs effectively.
            </dd>
        </div>
        <div class="relative" style={{width:'40%'}}>
            <dt>
                <div
                    class="absolute flex items-center justify-center  rounded-md bg-primary-500 text-white">
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-bag-check h-12 w-12" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>
                </div>
                <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-50"> E-commerce Solutions
                </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-100 par"> Our team designs and develops a robust e-commerce platform that provides a seamless shopping experience for customers by integrating secure payment gateways, inventory management systems, and optimizing e-commerce platform for search engines and mobile devices to maximize online sales.
            </dd>
        </div>
   
    </dl>
    
</div>
</div>
   
  </div>
 
  
</div>

<div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="title-holder-align-bottom">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        What we do for &nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        companies
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
    <Link to='/contact'>
    <div
      data-w-id="79057d9c-d2cf-dc34-a67a-c1fe0bc1a30a"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      <div
        className="btn-more-text btn-more-text-home-image"
        style={{
          fontSize:'11px',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        Contact Us
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>
    </div>
    </Link>

   
  </div>
</div>

<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'20vw',flexDirection:'column' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'40vw' }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>We build websites for businesses from scratch</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
          We design our website according to the client's preferences. Initially, we design your website on Figma and make sure the client is fully on board before converting the code to a real website. React is our preferred framework for building web applications and Tailwind CSS is used for designing user-friendly interfaces. Our back-end is powered by Firebase/AWS Amplify. 

Once your website is complete, we will hand over the code to our clients.Depending on the project scope, a website can be made for as little as 20k. All domain and hosting costs will be covered by us for the first year. In addition, you will be responsible for paying for the backend service, which is initially free until you pass the backend provider's limit (Firebase or AWS Amplify). After the backend limit is exceeded, you will be responsible for paying the backend service fees directly to the provider. The fees vary depending on the number of active users and amount of data stored. We can also provide you with an estimate for these costs if needed.
            </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto' }}

  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Connect businesses' websites with Google My Business and other social media</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            Google My Business is a free tool that allows businesses to manage their online presence on Google, including how they appear in Google Search and Google Maps. By connecting the client's website to their Google My Business profile, we ensure that crucial information like the business name, address, phone number, and website are consistent and up-to-date across Google's search results.


We also integrate the client's website with their active social media accounts, such as Facebook, Twitter, Instagram, LinkedIn, and others.This allows the website to display social media content (like posts and reviews) and provide easy access for visitors to follow and engage with the business on these platforms.

The key benefits of these integrations include Consistent Online Presence, Social Media Connectivity, Enhanced Engagement, and Streamlined Management.

By connecting our clients' websites with these important digital platforms, we help them expand their online reach, improve their credibility, and ultimately, attract more customers to their business. It's a win-win for our clients and their customers!
            </p>
          </div>
        </div>
      
      </div>
    </div>
  </div>
  
</div>

        </div>
        <Footer/>
    </div>
  )
}
