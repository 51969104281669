import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { v4 } from 'uuid';
import Nav from './nav';
import Navigation from '../No Auth/nav'


export default function NotFound() {
    const [uid,setuid] = useState('')
    const [user,setuser] = useState([])

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
           
    
       
        
    

      const userRef = doc(firestore,"Users",uid);
    
      onSnapshot(userRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      setuser(main)
    
        
        
      
    
      })
      
   
    
    
    
    
    
           
      
    
    
    
    
            
    
               
     
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);
  return (

<div>
{
          user.length != 0 ?
              <Nav/>
            :
            <Navigation/>
      }
  <section class="bg-white  " id='desktop'>
    <div class=" flex items-center  px-6 py-12 mx-auto">
        <div class="flex flex-col items-center max-w-sm mx-auto text-center">
            <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
            </p>
            <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
            Page not found</h1>
            <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

            <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
   <Link to='/'>
   <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>


                    <span>Go back</span>
                </button></Link>
      <Link to='/contact'>
                <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                    Contact Us
                </button>
      </Link>

            </div>
        </div>
    </div>
</section>
<section class="bg-white  " id='tablet'>
    <div class=" flex items-center  px-6 py-12 mx-auto">
        <div class="flex flex-col items-center max-w-sm mx-auto text-center">
            <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
            </p>
            <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
            Page not found</h1>
            <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

            <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
   <Link to='/'>
   <button class="flex items-center justify-center  px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>


                    <span>Go back</span>
                </button></Link>
      <Link to='/contact'>
                <button class=" px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                    Contact Us
                </button>
      </Link>

            </div>
        </div>
    </div>
</section>
<section class="bg-white  " id='mobile'>
    <div class=" flex items-center  px-6 py-12 mx-auto">
        <div class="flex flex-col items-center max-w-sm mx-auto text-center">
            <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
            </p>
            <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
            Page not found</h1>
            <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

            <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
   <Link to='/'>
   <button class="flex items-center justify-center  px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>


                    <span>Go back</span>
                </button></Link>
      <Link to='/contact'>
                <button class=" px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                    Contact Us
                </button>
      </Link>

            </div>
        </div>
    </div>
</section></div>
  )
}
