import React from 'react'
import { Link, } from 'react-router-dom';
import Nav from './nav';
import Footer from './footer';

export default function Services() {





   
   
  
   


  return (
    <div className='' style={{minHeight:'100vh'}}>
        <div id="desktop" className=''>
    <Nav component={'Services'}/>
    <div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="title-holder-align-bottom">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        OUR SERVICES FOR&nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        companies
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
    <Link to='/contact'>
    <div
      data-w-id="79057d9c-d2cf-dc34-a67a-c1fe0bc1a30a"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      <div
        className="btn-more-text btn-more-text-home-image"
        style={{
          fontSize:'11px',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        Contact Us
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>
    </div>
    </Link>

   
  </div>
</div>

<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'> Website Design and Development</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
   ⁃ Custom Website Development: Tailored websites built from scratch to meet specific client requirements.
<br />
- Responsive Design: Websites that work seamlessly on all devices (desktops, tablets, smartphones).
<br />

- E-commerce Development: Online stores with secure payment gateways and user-friendly interfaces.
<br />

- Content Management Systems (CMS): Platforms like WordPress, Joomla, or Drupal for easy content updates.         </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>UX/UI Design</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            - User Experience (UX) Design: Focusing on the overall feel of the product and ensuring it meets user needs.
            <br />- User Interface (UI) Design: Crafting visually appealing interfaces that are easy to navigate.
            </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',
        
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>SEO and Digital Marketing</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
- Search Engine Optimization (SEO): Improving website visibility on search engines. <br />
- Content Marketing: Creating valuable content to attract and engage users.<br />
- Social Media Marketing: Managing and promoting the business on social media platforms.<br />
- Pay-Per-Click (PPC) Advertising: Online advertising model to drive traffic to websites. <br /> </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Maintenance and Support</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
          ⁃ Website Maintenance: A 2-months free maintenance ie; updates, backups, and security checks to ensure website performance.
          <br />- Technical Support: Ongoing support to resolve technical issues and improve functionality. 
            </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'> Hosting and Domain Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
- Web Hosting: Providing server space to host websites for free. <br />
⁃ Domain Registration: Assisting in buying and managing domain names and offer a 1 year free trial.     </p>     </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Data Analytics </h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
        Website Analytics: Tracking and analyzing website performance and user behavior.  </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',
        
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Migration Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
 ⁃ Website Migration: Transferring websites from one host to another or from one platform to another.
 <br /> - Data Migration: Moving data securely between systems.    </p>     </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Security Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
        -  Website Security Audits: Evaluating and improving website security. <br />
        - SSL Certificate Installation: Ensuring secure data transmission between users and the website.   </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>


<Footer/>
        </div>
        <div id="tablet" className=''>
    <Nav component={'Services'}/>
    <div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="flex justify-center">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        OUR SERVICES FOR&nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        companies
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
    <Link to='/contact'>
    <div
      data-w-id="flex justify-center"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      <div
        className="btn-more-text btn-more-text-home-image"
        style={{
          fontSize:'11px',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        Contact Us
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>
    </div>
    </Link>

   
  </div>
</div>

<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'> Website Design and Development</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
   ⁃ Custom Website Development: Tailored websites built from scratch to meet specific client requirements.
<br />
- Responsive Design: Websites that work seamlessly on all devices (desktops, tablets, smartphones).
<br />

- E-commerce Development: Online stores with secure payment gateways and user-friendly interfaces.
<br />

- Content Management Systems (CMS): Platforms like WordPress, Joomla, or Drupal for easy content updates.         </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>UX/UI Design</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            - User Experience (UX) Design: Focusing on the overall feel of the product and ensuring it meets user needs.
            <br />- User Interface (UI) Design: Crafting visually appealing interfaces that are easy to navigate.
            </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',
        
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>SEO and Digital Marketing</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
- Search Engine Optimization (SEO): Improving website visibility on search engines. <br />
- Content Marketing: Creating valuable content to attract and engage users.<br />
- Social Media Marketing: Managing and promoting the business on social media platforms.<br />
- Pay-Per-Click (PPC) Advertising: Online advertising model to drive traffic to websites. <br /> </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Maintenance and Support</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
          ⁃ Website Maintenance: A 2-months free maintenance ie; updates, backups, and security checks to ensure website performance.
          <br />- Technical Support: Ongoing support to resolve technical issues and improve functionality. 
            </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'> Hosting and Domain Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
- Web Hosting: Providing server space to host websites for free. <br />
⁃ Domain Registration: Assisting in buying and managing domain names and offer a 1 year free trial.     </p>     </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Data Analytics </h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
        Website Analytics: Tracking and analyzing website performance and user behavior.  </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",marginBottom:'200px' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',
        
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Migration Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'15px',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
 ⁃ Website Migration: Transferring websites from one host to another or from one platform to another.
 <br /> - Data Migration: Moving data securely between systems.    </p>     </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '45%', }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Security Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'15px',
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
        -  Website Security Audits: Evaluating and improving website security. <br />
        - SSL Certificate Installation: Ensuring secure data transmission between users and the website.   </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>


<Footer/>
        </div>
        <div id="mobile" className=''>
    <Nav component={'Services'}/>
    <div data-w-id="c836b229-4071-c854-4c22-e6b780afd940" className="arrow-holder px-6 w-full flex items-center">
  <div className="flex justify-center">
    <div
      data-w-id="c836b229-4071-c854-4c22-e6b780afd941"
      style={{
        transform:
          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
      className="about-blue-line"
    />
    <div className="title-holder">
      <div
        data-w-id="c836b229-4071-c854-4c22-e6b780afd942"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        OUR SERVICES FOR&nbsp;
      </div>
      <div
        data-w-id="43955262-f967-0ffc-fe31-1192bd44d408"
        style={{
          transform:
            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1
        }}
        className="title-small-15px"
      >
        companies
      </div>
    </div>
  </div>
  <div
    data-w-id="c836b229-4071-c854-4c22-e6b780afd944"
    style={{ opacity: 1 }}
    className="our-work-slide-nav"
  >
    <Link to='/contact'>
    <div
      data-w-id="flex justify-center"
      className="button-secondary w-inline-block"
      style={{
        willChange: "transform",
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div
        className="hover-btn-layer hover-btn-layer-blue"
        style={{ width: "0%" }}
      />
      <div
        className="btn-more-text btn-more-text-home-image"
        style={{
          fontSize:'11px',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        Contact Us
      </div>
      <div
        className="btn-image-holder"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/5d27763c46ba9e561dc5b972/657780a9e33d14bdb07d45f0_Frame%201160445237.svg"
          loading="lazy"
          alt=""
          className="btn-image"
        />
      </div>
    </div>
    </Link>

   
  </div>
</div>

<div
  className="swiper-wrapper flex-col"
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",flexDirection:'column' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'10vw' }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'> Website Design and Development</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'3vw',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
   ⁃ Custom Website Development: Tailored websites built from scratch to meet specific client requirements.
<br />
- Responsive Design: Websites that work seamlessly on all devices (desktops, tablets, smartphones).
<br />

- E-commerce Development: Online stores with secure payment gateways and user-friendly interfaces.
<br />

- Content Management Systems (CMS): Platforms like WordPress, Joomla, or Drupal for easy content updates.         </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'10vw' }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>UX/UI Design</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'3vw',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
            - User Experience (UX) Design: Focusing on the overall feel of the product and ensuring it meets user needs.
            <br />- User Interface (UI) Design: Crafting visually appealing interfaces that are easy to navigate.
            </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",flexDirection:'column' }}
>
<div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'10vw' }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Maintenance and Support</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'3vw',
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
          ⁃ Website Maintenance: A 2-months free maintenance ie; updates, backups, and security checks to ensure website performance.
          <br />- Technical Support: Ongoing support to resolve technical issues and improve functionality. 
            </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'10vw' }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',
        
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>SEO and Digital Marketing</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'3vw',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
- Search Engine Optimization (SEO): Improving website visibility on search engines. <br />
- Content Marketing: Creating valuable content to attract and engage users.<br />
- Social Media Marketing: Managing and promoting the business on social media platforms.<br />
- Pay-Per-Click (PPC) Advertising: Online advertising model to drive traffic to websites. <br /> </p>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>

  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",flexDirection:'column' }}
>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'10vw' }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'> Hosting and Domain Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'3vw',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
- Web Hosting: Providing server space to host websites for free. <br />
⁃ Domain Registration: Assisting in buying and managing domain names and offer a 1 year free trial.     </p>     </div>
        </div>
       
      </div>
    </div>
   
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'10vw' }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',

          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Data Analytics </h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'3vw',

                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
        Website Analytics: Tracking and analyzing website performance and user behavior.  </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  
</div>
<div
  className="swiper-wrapper "
  id="swiper-wrapper-110837ba7e2a9da610"
  aria-live="polite"
  style={{  transform: "translate3d(0px, 0px, 0px)",flexDirection:'column'}}
> <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'10vw' }}
  >
    <div className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'rgb(255, 0, 43)',
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Security Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                willChange: "transform",
                fontSize:'3vw',
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
        -  Website Security Audits: Evaluating and improving website security. <br />
        - SSL Certificate Installation: Ensuring secure data transmission between users and the website.   </p>
          </div>
        </div>
     
      </div>
    </div>
  </div>
  <div
    className="swiper-slide swiper-slide-ow swiper-slide-active"
    role="group"
    aria-label="1 / 4"
    style={{ width: '90%',margin:'auto',marginBottom:'10vw' }}
  >
    <div target='_blank' className="card-slide-link w-inline-block">
      <div
        className="slide-case-studies-card slide-case-studies-card-4"
        style={{
          backgroundColor:'#1b05e4',
        
          willChange: "transform",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0.1238deg) rotateY(2.9043deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d"
        }}
      >
        <div
          className="bot-marg-48"
          style={{
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="ow-logo-holder">
           <h1 className='text-white text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl'>Migration Services</h1>

          </div>
        </div>
        <div
          className="bot-marg-80 bot-marg-80-animation"
          style={{
          
            willChange: "transform",
            transform:
              "translate3d(3.8724px, 0.4952px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <div className="holder-mw-440">
            <p
              className="case-studies-text "
              style={{
                fontSize:'3vw',
                willChange: "transform",
                transform:
                  "translate3d(0px, 0.85008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d"
              }}
            >
 ⁃ Website Migration: Transferring websites from one host to another or from one platform to another.
 <br /> - Data Migration: Moving data securely between systems.    </p>     </div>
        </div>
       
      </div>
    </div>
   
  </div>
 
  
</div>


<Footer/>
        </div>
    </div>
  )
}
