import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyDHv01LVHwn240xVZUYwWIiM4D_wVFe3WU",
    authDomain: "shewit-bakery.firebaseapp.com",
    projectId: "shewit-bakery",
    storageBucket: "shewit-bakery.appspot.com",
    messagingSenderId: "1015056195872",
    appId: "1:1015056195872:web:aa698c57b713521f397745"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
